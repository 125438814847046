.App {
  font-family: "BAggroB", "Jalnan";
}

@font-face {
  font-family: "Gangwon";
  src: url("./assets/fonts/gang-won_bold.ttf");
}

@font-face {
  font-family: "BAggroB";
  src: url("./assets/fonts/SB_B.woff");
}

@font-face {
  font-family: "Jalnan";
  src: url("./assets/fonts/Jalnan.ttf");
}
